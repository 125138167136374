export default {
  computed: {
    canUpdate() {
      if (this.permissions) {
        return this.permissions.canUpdate
      }
      return false
    },
    canChangePrice() {
      if (this.permissions) {
        return this.permissions.canChangePrice
      }
      return false
    },
    canChangePromoCode() {
      if (this.permissions) {
        return this.permissions.canChangePromoCode
      }
      return false
    },
    canCancel() {
      if (this.permissions) {
        return this.permissions.canCancel
      }
      return false
    },
    canWelcome() {
      if (this.permissions) {
        return this.permissions.canWelcome
      }
      return false
    },
    canComplete() {
      if (this.permissions) {
        return this.permissions.canComplete
      }
      return false
    },
    canConfirm() {
      if (this.permissions) {
        return this.permissions.canConfirm
      }
      return false
    },
    canLockVisit() {
      if (this.permissions) {
        return this.permissions.canLockVisit
      }
      return false
    },
    canStaffUpdateAnytime() {
      if (this.permissions) {
        return this.permissions.canStaffUpdateAnytime
      }
      return false
    },
    canRequestETA() {
      if (this.permissions) {
        return this.permissions.canRequestETA
      }
      return false
    },
    canRequestChanges() {
      if (this.permissions) {
        return this.permissions.canRequestChanges
      }
      return false
    },
    upkeepAllowed() {
      if (this.permissions) {
        return this.permissions.upkeepAllowed
      }
      return false
    }
  }
}
